<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="物业用户" prop="sysUser">
                <a-input v-model="queryParam.sysUserName" placeholder="请输入物业用户" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="名称" prop="title">
                <a-input v-model="queryParam.title" placeholder="请输入名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="小区物业电话" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入小区物业电话" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">


              <a-col :md="6" :sm="24">
                <a-form-item label="是否开放论坛" prop="isDynamic">
                  <a-select v-model="queryParam.isDynamic" placeholder="请选择" style="width: 100%" allow-clear>
                    <a-select-option :value="0">否</a-select-option>
                    <a-select-option :value="1">是</a-select-option>
                  </a-select>

                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="论坛是否审核" prop="isApproveDynamic">
                  <a-select v-model="queryParam.isApproveDynamic" placeholder="请选择" style="width: 100%" allow-clear>
                    <a-select-option :value="0">否</a-select-option>
                    <a-select-option :value="1">是</a-select-option>
                  </a-select>

                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="论坛订单是否审核" prop="isApproveOrder">
                  <a-select v-model="queryParam.isApproveOrder" placeholder="请选择" style="width: 100%" allow-clear>
                    <a-select-option :value="0">否</a-select-option>
                    <a-select-option :value="1">是</a-select-option>
                  </a-select>

                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['user:village:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['user:village:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['user:village:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['user:village:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <village-building-list   ref='villageBuildingList' @ok='getList'/>
      <village-lock-list ref='villageLockList' @ok='getList'/>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['user:village:edit']" />
          <a @click="$refs.villageBuildingList.show(record)" v-hasPermi="['user:village:edit']">
            <a-icon type="edit" />楼栋
          </a>
          <a-divider type="vertical" v-hasPermi="['user:village:edit']" />
          <a @click="$refs.villageLockList.show(record)" v-hasPermi="['user:village:edit']">
            <a-icon type="edit" />锁
          </a>
          <a-divider type="vertical" v-hasPermi="['user:village:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['user:village:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['user:village:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['user:village:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageVillage,listVillage, delVillage } from '@/api/village/village'
import CreateForm from '@/views/village/village/modules/CreateForm.vue'
import VillageBuildingList from '@/views/village/village/modules/VillageBuildingList.vue'
import VillageLockList from '@/views/village/village/modules/VillageLockList.vue'
import { tableMixin } from '@/store/table-mixin'
import StoreUserList from '@/views/store/modules/StoreUserList.vue'

export default {
  name: 'Village',
  components: {
    StoreUserList,
    CreateForm,VillageBuildingList,VillageLockList
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        sysUserName: null,
        title: null,
        areap: null,
        areac: null,
        areax: null,
        address: null,
        lng: null,
        lat: null,
        mobile: null,
        isDynamic: null,
        isApproveDynamic: null,
        isApproveOrder: null,
        desc: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          ellipsis: true,
          customRender: (text, record, index) => index + 1,
          align: 'center'
        },
        {
          title: '物业用户',
          dataIndex: 'sysUserName',
          ellipsis: true,
          align: 'center',
          customRender: function (t, r, index) {
            if(!t) {
              return '未设置'
            }
            return t
          }

        },
        {
          title: '名称',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '省份',
        //   dataIndex: 'areap',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '城市',
        //   dataIndex: 'areac',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '县',
        //   dataIndex: 'areax',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '详细地址',
          dataIndex: 'address',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '经度',
        //   dataIndex: 'lng',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '纬度',
        //   dataIndex: 'lat',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '小区物业电话',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否开放论坛',
          dataIndex: 'isDynamic',
          ellipsis: true,
          align: 'center',
          customRender: function (t, r, index) {
            if(t == 0) {
              return '否'
            }else if(t == 1) {
              return '是'
            }
          }
        },
        {
          title: '论坛是否审核',
          dataIndex: 'isApproveDynamic',
          ellipsis: true,
          align: 'center',
          customRender: function (t, r, index) {
            if(t == 0) {
              return '否'
            }else if(t == 1) {
              return '是'
            }
          }
        },
        {
          title: '论坛置顶是否审核',
          dataIndex: 'isApproveOrder',
          ellipsis: true,
          align: 'center',
          customRender: function (t, r, index) {
            if(t == 0) {
              return '否'
            }else if(t == 1) {
              return '是'
            }
          }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '15%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询小区列表 */
    getList () {
      this.loading = true
     pageVillage(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {


      this.queryParam = {
        sysUserName: undefined,
        title: undefined,
        areap: undefined,
        areac: undefined,
        areax: undefined,
        address: undefined,
        lng: undefined,
        lat: undefined,
        mobile: undefined,
        isDynamic: undefined,
        isApproveDynamic: undefined,
        isApproveOrder: undefined,
        desc: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delVillage(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/village/export', {
            ...that.queryParam
          }, `小区_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
