<template>
  <a-modal title='小区锁' :visible="visible" width="70%"
           @cancel="handleCancel"
           @ok="handleOk" cancelText='关闭'  >
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="名称" prop="title">
                <a-input v-model="queryParam.title" placeholder="请输入名称" allow-clear/>
              </a-form-item>
            </a-col>


            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operations">
        <a-button type="primary" @click="$refs.lockForm.handleAdd(villageId)"  >
          <a-icon type="plus" />新增
        </a-button>
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" >-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
      </div>

      <lock-create-form ref='lockForm' @ok='getLockList' />
      <card-create-form ref='cardForm' @ok='getLockList' />
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <!--      -->
        <span slot="img" slot-scope="text,record">
          <img v-if='record.avatar' :src="record.avatar" style="width: 80px;height: 80px;"/>
           <span v-else>无</span>
        </span>

        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>

        <span slot='operation' slot-scope='text, record'>

          <a @click="$refs.lockForm.handleUpdate(record, undefined)"  >
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical"   />
          <a @click='handleDelete(record)' >
            <a-icon type='delete' />删除
          </a>
          <a-divider type="vertical"   />
           <a @click="$refs.cardForm.handleAdd(record.id, undefined)"  >
            <a-icon type="edit" />加卡减卡
          </a>

        </span>
      </a-table>
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>

  </a-modal>
</template>

<script>

import { pageLock, delLock, getLock } from '@/api/village/lock'
import LockCreateForm from '@/views/village/village/modules/LockCreateForm.vue'
import CardCreateForm from '@/views/village/village/modules/CardCreateForm.vue'
import { tableMixin } from '@/store/table-mixin'
import village from '@/views/village/village/index.vue'
export default {
  name: 'GraphCategory',
  components: {
    LockCreateForm,CardCreateForm
  },
  mixins: [tableMixin],

  data() {
    return {
      advanced:false,

      vipId:null,
      visible:false,
      multiple:false,
      list: [],
      // 展开的行，受控属性
      expandedRowKeys: [],
      replaceFields: {
        id: "id",
        pid: "pid",
        children: "childList"
      },
      sortParams: {
        field: "sort",
        way: "ASC"
      },
      selectedRowKeys: [],
      selectedRows: [],

      ids: [],
      loading: false,
      total: 0,
      villageId: null,
      // 查询参数
      queryParam: {
        title: null,
        villageId: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '#',
          dataIndex: '',
          ellipsis: true,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },

        {
          title: '名称',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '编号',
          dataIndex: 'lockNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '楼栋',
          dataIndex: 'building',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '单元',
          dataIndex: 'unit',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '锁状态',
          dataIndex: 'lockStatus',
          ellipsis: true,
          align: 'center',
          customRender: function (t, r, index) {
            if(t == 0){
              return "正常"
            }else if(t == 1){
              return "离线"
            }
          }
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {

  },
  computed: {},
  watch: {},
  methods: {

    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getLockList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        villageId: this.villageId,
        title: undefined,

        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    show(record){
      this.selectedRowKeys = []
      this.selectedRows = []
      this.visible = true;
      this.multiple = false
      this.villageId = record.id;
      this.queryParam.villageId = record.id;

      this.getLockList();

    },
    /** 查询引用列表 */
    getLockList() {
      this.loading = true
      pageLock(this.queryParam).then(response => {
        this.list = response.data.records;
        this.total = response.data.total
        this.loading = false
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    handleOk(){
      this.visible = false
      this.$emit('ok')
    },
    handleCancel(){
      this.visible = false
      this.$emit('ok')
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },

    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      if(this.ids.length<1){
        that.$message.error(
          '请选择数据',
          3
        );
        return
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delLock(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getLockList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
  }
}
</script>
<style lang='less' scoped></style>